<template>
  <div class="activity-detail wrapper page-wrap" v-if="activity">
    <a-spin :spinning="loading">
      <div class="detail-top flex">
        <img :src="activity.activity_image" />
        <div class="detail-list">
          <div class="h1">{{ activity.activity_title }}</div>
          <div class="flex flex-between a-list">
            <div class="list-title flex">
              <img src="~@/assets/calendar.png" alt="" />
              <span>报名时间</span>
            </div>
            <div class="list-content">
              {{ activity.activity_apply_start_time }} —
              {{ activity.activity_apply_end_time }}
            </div>
          </div>
          <div class="flex flex-between a-list">
            <div class="list-title flex">
              <img src="~@/assets/time.png" alt="" />
              <span>活动时间</span>
            </div>
            <div class="list-content">
              {{ activity.activity_start_time }} —
              {{ activity.activity_end_time }}
            </div>
          </div>
          <div class="flex flex-between a-list">
            <div class="list-title flex">
              <img src="~@/assets/address-active.png" alt="" />
              <span>活动地点</span>
            </div>
            <div class="list-content">
              {{ activity.activity_address }}
            </div>
          </div>
          <div class="flex flex-between a-list">
            <div class="list-title flex">
              <img src="~@/assets/person.png" alt="" />
              <span>人数限制</span>
            </div>
            <div class="list-content">
              <strong style="color: #1276cb">
                {{
                  activity.activity_limit == -1
                    ? "不限"
                    : activity.activity_limit + "人"
                }}
              </strong>
              <span v-if="activity.activity_apply_num" style="margin-left: 20px"
                ><strong style="color: #1276cb">{{
                  activity.activity_apply_num
                }}</strong
                >人报名</span
              >
            </div>
          </div>
          <div class="flex flex-between a-list">
            <div class="list-title flex">
              <img src="~@/assets/address-active.png" alt="" />
              <span>联系电话</span>
            </div>
            <div class="list-content">
              {{ activity.activity_phone }}
            </div>
          </div>
        </div>
      </div>
      <div class="line"></div>
      <div class="activity-content" v-html="activity.activity_content"></div>
      <div
        v-if="activity.activity_attachment?.length > 0"
        style="padding-bottom: 50px"
      >
        <div class="line"></div>
        <attachment :data="activity.activity_attachment"></attachment>
      </div>
      <a-button class="btn" type="primary" @click="goToApply">报名</a-button>
    </a-spin>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  reactive,
  toRefs,
  watch
} from "@vue/runtime-core";
import { getActivityData } from "@/api/main.js";
import { message } from "ant-design-vue";
import url from "@/utils/url.js";
import { getUserId, getUserData } from "@/utils/storeData.js";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { encode, decode } from "js-base64";
import Attachment from "@/components/attachment.vue";

export default defineComponent({
  name: "activityDetail",
  components: {
    Attachment
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const pageData = reactive({
      activity: null,
      activityId: null,
      loading: true
    });
    const getActivityDataFun = _activityId => {
      getActivityData({ activity_id: _activityId })
        .then(res => {
          if (res.code == 200) {
            pageData.activity = res.data.activity;
          } else {
            message.error(res.msg);
          }
        })
        .catch(res => {
          console.log(res);
          message.error("网络有误，请稍后再试");
        })
        .finally(() => {
          pageData.loading = false;
        });
    };
    const goToApply = () => {
      url.navigateTo("/activity/apply", {
        t: encode(`${pageData.activity.activity_id}`)
      });
    };
    watch(
      () => route.query,
      val => {
        if (route.path == "/activity/detail") {
          let query = decode(val?.t).split(",");
          if (query[0]) {
            pageData.loading = true;
            pageData.activityId = query[0];
            getActivityDataFun(query[0]);
          } else {
            pageData.loading = false;
            pageData.activity = null;
            message.error("暂无活动信息");
          }
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
    return {
      ...toRefs(pageData),
      goToApply
    };
  }
});
</script>
<style lang="less" scoped>
.activity-detail {
  .detail-top {
    margin-top: 40px;
    margin-bottom: 50px;
    align-items: flex-start;
    > img {
      width: 581px;
      height: 401px;
      background-color: #1276cb;
      border-radius: 10px;
    }
    .detail-list {
      margin-left: 40px;
      width: 590px;
      .h1 {
        font-size: 24px;
        color: #212531;
        margin-bottom: 30px;
      }
      .a-list {
        height: 56px;
        border-bottom: 1px solid #dfdfdf;
        font-size: 18px;
        color: #212531;
        .list-title {
          img {
            margin-right: 10px;
          }
        }
      }
    }
  }
  .activity-content {
    padding: 40px 0;
  }
  .line {
    height: 10px;
    background-color: #f3f5f8;
  }
  .apply-btn {
    width: 400px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    background-color: #1276cb;
    border-radius: 30px;
    font-size: 20px;
    color: #ffffff;
    margin: 90px auto;
    cursor: pointer;
  }
}
</style>
